<template>
  <v-dialog v-model="dialog" width="900" flat persistent transition="dialog-bottom-transition">
    <v-card raised="raised">
      <v-toolbar flat dark color="primary">
        <!-- close btn -->
        <v-btn icon dark @click="closeCrop">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <!-- title -->
        <v-toolbar-title> <v-icon color="secondary" left="left">mdi-account-box</v-icon>Nova Imagem - {{ image.label }} </v-toolbar-title>
        <v-spacer />
        Tamanho: {{ image.width + 'x' + image.height }} / Tipo: {{ image.type }}
      </v-toolbar>
      <v-card-text>
        <!-- image input -->
        <v-file-input
          v-model="selectedFile"
          class="my-4"
          :accept="`image/${image.type}`"
          label="File"
          placeholder="Selecionar arquivo"
          :show-size="true"
          @change="setupCropper"
        ></v-file-input>
        <!-- alert -->
        <v-alert v-if="canvasAlert" block outlined dense type="error"> Tamanho de imagem incorreto: {{ incorrectSize }} </v-alert>
        <v-row v-if="objectUrl !== null || !objectUrl">
          <!-- original image -->
          <v-col v-if="selectedFile" class="text-center" cols="12" sm="6">
            <div class="overline">Imagem Original</div>
            <div class="image-container elevation-4"><img ref="source" class="image-preview" :src="objectUrl" /></div>
          </v-col>
          <!-- preview image -->
          <v-col v-if="selectedFile" class="text-center" cols="12" sm="6">
            <div class="overline">Prévia</div>
            <div class="image-container elevation-4"><img class="image-preview" :src="previewCropped" /></div>
            <div v-if="cropper && cropper.cropBoxData" class="overline">
              Escala: {{ this.cropper.cropBoxData.width.toFixed(2) + 'x' + this.cropper.cropBoxData.height.toFixed(2) }}
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" :disabled="!objectUrl || canvasAlert" @click="saveCrop"> <v-icon left="left">mdi-send</v-icon><span>Submit</span> </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
import debounce from 'lodash/debounce'

export default {
  props: {
    dialog: Boolean,
    image: {
      type: Object,
      default: () => {}
    },
    imagesToUpload: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      cropper: null,
      objectUrl: null,
      previewCropped: null,
      selectedFile: null,
      debouncedUpdatePreview: debounce(this.updatePreview, 257),
      canvasAlert: false,
      incorrectSize: null
    }
  },
  methods: {
    setupCropper(selectedFile) {
      if (this.cropper) {
        this.cropper.destroy()
      }

      if (this.objectUrl) {
        window.URL.revokeObjectURL(this.objectUrl)
      }

      if (!selectedFile) {
        this.cropper = null
        this.objectUrl = null
        this.previewCropped = null
        return
      }

      this.objectUrl = window.URL.createObjectURL(selectedFile)
      this.$nextTick(this.setupCropperInstance)
    },
    setupCropperInstance() {
      const ratio = this.image.width / this.image.height
      const image = this.$refs.source
      this.cropper = new Cropper(image, {
        crop: this.debouncedUpdatePreview,
        cropBoxResizable: false,
        autoCropArea: 0,
        dragMode: 'move',
        viewMode: 2,
        aspectRatio: ratio,
        initialAspectRatio: ratio,
        movable: false,
        zoomable: false,
        data: { width: this.image.width, height: this.image.height }
      })
    },
    updatePreview(event) {
      const canvas = this.cropper.getCroppedCanvas()
      this.previewCropped = canvas.toDataURL(`image/${this.image.type}`, { width: this.image.width, height: this.image.height })
      if (+canvas.width !== this.image.width || +canvas.height !== this.image.height) {
        this.canvasAlert = true
        this.incorrectSize = `${canvas.width}x${canvas.height}`
      } else {
        this.canvasAlert = false
        this.incorrectSize = null
      }
    },
    saveCrop() {
      const canvas = this.cropper.getCroppedCanvas()

      canvas.toBlob(async blob => {
        const formData = new FormData()

        formData.append(this.image.label.toLowerCase(), blob, `${this.image.label.toLowerCase()}.${this.image.type}`)

        // data file
        const data = formData.entries().next()

        let reader = new FileReader()

        reader.readAsDataURL(blob)
        reader.onload = () => {
          this.imagesToUpload.push({
            imagePreview: reader.result,
            tag: this.image.label,
            file: data['value'][1]
          })
          this.$emit('update:imagesToUpload', this.imagesToUpload)
          this.$emit('close')
        }
      })
    },
    closeCrop() {
      this.selectedFile = null
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss" scoped>
.image-container {
  display: inline-block;
}

.image-preview {
  display: block;
  max-height: 229px;
  max-width: 100%;
}
</style>
